import isSessionStorageAvailable from './sessionStorageAvailable';

const setSessionStorageItem = <T>(key: string, value: T): boolean => {
  if (!isSessionStorageAvailable) return false;

  const partnerKey = `${process.env.NEXT_PUBLIC_PARTNER}_${key}`;
  sessionStorage.setItem(partnerKey, JSON.stringify(value));
  return true;
};

export default setSessionStorageItem;
