'use client';

import { useEffect } from 'react';

import { AB_SMARTLOOK } from '@/constants';
import { hasFeature } from '@/services/abFeatures/client';
import { enableSmartlook } from '@/utils/smartlook/enableSmartlook';

function Smartlook() {
  useEffect(() => {
    (async () => {
      const isSmartlookEnabled = await hasFeature(AB_SMARTLOOK);

      if (isSmartlookEnabled) {
        enableSmartlook();
      }
    })();
  }, []);

  return null;
}

export default Smartlook;
