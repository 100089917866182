'use client';

import { useEffect } from 'react';

import useAdBlock from '@/hooks/useAdblock';
import { sendLog } from '@/services/logs';

export default function AdBlockLogger() {
  const hasAdBlock = useAdBlock();

  useEffect(() => {
    if (hasAdBlock !== undefined) {
      sendLog('app_adblock_detection', {
        status: hasAdBlock ? 'blocked' : 'not blocked',
      });
    }
  }, [hasAdBlock]);

  return null;
}
