import { UploaderImage, UploaderStatus } from '../../types/image';
import { UploaderJob } from '../../types/job';

import getImageCallbacksByStatus from './getImageCallbacksByStatus';

const runImageCallbacksByStatus = <T extends UploaderStatus>(
  job: UploaderJob,
  image: UploaderImage<T>,
) => {
  const imageCallbacksByStatus = getImageCallbacksByStatus<T>(
    job.imageStatusChangedCallbacks,
    image.status,
  );
  imageCallbacksByStatus.forEach((callback) => {
    callback.callback(image);
  });
};

export default runImageCallbacksByStatus;
