import { UploaderJob } from '../../types/job';

import getJobCallbacksByStatus from './getJobCallbacksByStatus';

const runJobCallbacksByStatus = (job: UploaderJob) => {
  const jobCallbacksByStatus = getJobCallbacksByStatus<typeof job.status>(
    job.jobStatusChangedCallbacks,
    job.status,
  );
  jobCallbacksByStatus.forEach((action) => {
    action.callback(job);
  });
};

export default runJobCallbacksByStatus;
