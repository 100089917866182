// COPIED FROM: https://github.com/vercel/next.js/blob/canary/packages/third-parties/src/google/gtm.tsx

'use client';

// TODO: Evaluate import 'client only'
import React from 'react';
import Script from 'next/script';

export type GTMParams = {
  gtmId: string;
  dataLayer?: string[];
  dataLayerName?: string;
  auth?: string;
  preview?: string;
  proxyUrl?: string;
};

let currDataLayerName: string | undefined;

export function GoogleTagManager({
  gtmId,
  dataLayerName = 'dataLayer',
  auth,
  preview,
  dataLayer,
  proxyUrl,
}: GTMParams) {
  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName;
  }

  const gtmLayer = dataLayerName !== 'dataLayer' ? `&l=${dataLayerName}` : '';
  const gtmAuth = auth ? `&gtm_auth=${auth}` : '';
  const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : '';

  return (
    <>
      <Script
        id="_next-gtm-init"
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,l){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        ${dataLayer ? `w[l].push(${JSON.stringify(dataLayer)})` : ''}
      })(window,'${dataLayerName}');`,
        }}
      />
      <Script
        id="_next-gtm"
        data-ntpc="GTM"
        src={`${proxyUrl || 'https://www.googletagmanager.com'}/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
      />
    </>
  );
}

export const sendGTMEvent = (data: unknown) => {
  if (currDataLayerName === undefined) {
    // console.debug(`GTM has not been initialized`);
    return;
  }

  // @ts-expect-error
  if (window[currDataLayerName]) {
    // @ts-expect-error
    window[currDataLayerName].push(data);
  } else {
    // console.debug(`GTM dataLayer ${currDataLayerName} does not exist`);
  }
};
