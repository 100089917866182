import dataURItoBlob from './dataUriToBlob';

function dataURItoFile(
  dataURI: string,
  opts: { mimeType?: string; name?: string },
): File {
  return dataURItoBlob(dataURI, opts, true);
}

export default dataURItoFile;
