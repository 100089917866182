'use client';

import { ComponentProps, CSSProperties } from 'react';
import dynamic from 'next/dynamic';
import { Toaster as BaseToaster } from 'sonner';

import styles from './styles.module.scss';

type ToasterProps = ComponentProps<typeof BaseToaster>;

const LazyBaseToaster = dynamic(
  () =>
    import(/* webpackChunkName: "sonner" */ 'sonner').then((m) => m.Toaster),
  {
    ssr: false,
  },
);

function LazyToaster({ ...props }: ToasterProps) {
  return (
    <LazyBaseToaster
      theme="system"
      toastOptions={{
        classNames: {
          toast: styles.toast,
        },
      }}
      // use style to override the default style of the toaster library
      style={
        {
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          '--width': 'max-content',
          '--mobile-offset': '0',
        } as CSSProperties
      }
      offset={12}
      {...props}
    />
  );
}

export default LazyToaster;
