import { UploaderStatus } from '../../types/image';

const getPreviousStatus = (
  status: UploaderStatus,
): UploaderStatus | undefined => {
  const statusIndex = Object.values(UploaderStatus).indexOf(status);
  return Object.values(UploaderStatus)[
    statusIndex === 0 ? statusIndex : statusIndex - 1
  ];
};
export default getPreviousStatus;
