import { LOCAL_TAG_KEY, PICTARINE_VERSION_CODE } from '@/constants';

import getLocalStorageItem from './localStorage/getLocalStorageItem';
import setLocalStorageItem from './localStorage/setLocalStorageItem';

const isSameCodeVersion = () => {
  const versionTag = getLocalStorageItem<number | undefined>(
    LOCAL_TAG_KEY,
    undefined,
  );

  if (!versionTag || versionTag !== PICTARINE_VERSION_CODE) {
    setLocalStorageItem(LOCAL_TAG_KEY, PICTARINE_VERSION_CODE);
    return false;
  }
  return true;
};

export default isSameCodeVersion;
