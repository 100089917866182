import {
  UPLOADER_INDEXED_DB_NAME,
  UPLOADER_INDEXED_DB_STORE_NAME,
} from '@/context/Uploader/constants';
import { sendLog } from '@/services/logs';
import dataUrlToFile from '@/utils/image/dataUriToFile';

let db: IDBDatabase;
let objectStore: IDBObjectStore;

const openDB = async (): Promise<IDBDatabase> => {
  if (db) {
    return db;
  }

  return new Promise((resolve, reject) => {
    const request: IDBOpenDBRequest = indexedDB.open(
      UPLOADER_INDEXED_DB_NAME,
      1,
    );

    request.onerror = (event) => {
      sendLog(
        'app_uploader_open_indexedDB_failed',
        {
          event,
        },
        { now: true },
      );
      reject(new Error('Failed to open indexedDB'));
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(db);
    };

    request.onupgradeneeded = () => {
      db = request.result;
      objectStore = db.createObjectStore(UPLOADER_INDEXED_DB_STORE_NAME, {
        keyPath: 'id',
      });
      objectStore.createIndex('id', 'id', { unique: true });
      objectStore.transaction.onerror = () => {
        reject(new Error('Failed to create object store'));
      };
    };
  });
};

export const addImage = async (image: { id: string; dataUrl: string }) => {
  const request = (await openDB())
    .transaction(UPLOADER_INDEXED_DB_STORE_NAME, 'readwrite')
    .objectStore(UPLOADER_INDEXED_DB_STORE_NAME)
    .add(image);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(db);
    request.onerror = (event) => {
      sendLog(
        'app_uploader_add_to_indexedDB_failed',
        {
          event,
          id: image.id,
        },
        { now: true },
      );
      reject(new Error('Failed to add image to indexedDB'));
    };
  });
};

export const deleteImage = async (id: string) => {
  const request = (await openDB())
    .transaction(UPLOADER_INDEXED_DB_STORE_NAME, 'readwrite')
    .objectStore(UPLOADER_INDEXED_DB_STORE_NAME)
    .delete(id);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(db);
    request.onerror = (event) => {
      sendLog(
        'app_uploader_delete_from_indexedDB_failed',
        {
          event,
          id,
        },
        { now: true },
      );
      reject(new Error('Failed to delete image from indexedDB'));
    };
  });
};

export const getImage = async (id: string): Promise<File> => {
  const request = (await openDB())
    .transaction(UPLOADER_INDEXED_DB_STORE_NAME, 'readonly')
    .objectStore(UPLOADER_INDEXED_DB_STORE_NAME)
    .get(id);

  return new Promise<File>((resolve, reject) => {
    request.onsuccess = () => {
      try {
        const newFile = dataUrlToFile(
          request.result.dataUrl,
          request.result.id,
        );
        resolve(newFile);
      } catch (error) {
        reject(error);
      }
    };

    request.onerror = (event) => {
      sendLog(
        'app_uploader_get_from_indexedDB_failed',
        {
          event,
          id,
        },
        { now: true },
      );
      reject(new Error('Failed to get image from indexedDB'));
    };
  });
};
