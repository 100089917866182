import { UploaderImage, UploaderStatus } from '@/context/Uploader/types/image';
import { UploaderImageStatusChangedCallback } from '@/context/Uploader/types/job';
import { deleteCartItemByFileId } from '@/stores/Cart/CartStore';

const removeCartItem =
  (): UploaderImageStatusChangedCallback<UploaderStatus> => ({
    statuses: [UploaderStatus.Failed],
    callback: (image: UploaderImage<UploaderStatus>) => {
      deleteCartItemByFileId(image.id);
    },
  });

export default removeCartItem;
