import {
  UploaderJobReducerAction,
  UploaderReducerActionType,
} from '@/context/Uploader/types/job';
import { sendLog } from '@/services/logs';

const handlePrepareImageError = ({
  id,
  name,
  message,
  dispatch,
}: {
  id: string;
  name: string;
  message: string;
  dispatch: (value: UploaderJobReducerAction) => void;
}) => {
  sendLog('app_upload_file_state', {
    state: 'prepared_failed',
    id,
    name,
    value: message,
  });
  dispatch({
    type: UploaderReducerActionType.SetImageAsFailed,
    payload: {
      id,
      error: new Error(message),
    },
  });
};

export default handlePrepareImageError;
