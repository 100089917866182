'use client';

import React, { ReactNode, useMemo } from 'react';

import { Brand } from '@/types/brand';

import { BrandContext } from './context';

interface BrandProviderProps {
  brand: Brand;
  children: ReactNode;
}

// eslint-disable-next-line import/prefer-default-export
export function BrandProvider({ children, brand }: BrandProviderProps) {
  return (
    <BrandContext.Provider value={useMemo(() => ({ brand }), [brand])}>
      {children}
    </BrandContext.Provider>
  );
}
