import Cookies from 'js-cookie';
import SmartlookClient from 'smartlook-client';

import { COOKIE_DEVICE_ID } from '@/constants';
import { sendLog } from '@/services/logs';

// eslint-disable-next-line import/prefer-default-export
export function enableSmartlook() {
  if (SmartlookClient.initialized()) return;

  const deviceId = Cookies.get(COOKIE_DEVICE_ID);

  if (process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY && deviceId) {
    sendLog('ui_smartlook_enabled', {
      deviceId,
    });
    SmartlookClient.init(process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY);
    SmartlookClient.identify(deviceId, {});
    SmartlookClient.record({
      forms: true,
      emails: true,
      numbers: true,
    });
  }
}
