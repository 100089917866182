import { useEffect, useState } from 'react';

const useAdBlock = () => {
  const [hasAdblock, setHasAdBlock] = useState<boolean | undefined>();

  useEffect(() => {
    fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
      method: 'HEAD',
      mode: 'no-cors',
    })
      .then((response) => {
        setHasAdBlock(response.redirected);
      })
      .catch(() => {
        setHasAdBlock(window.navigator.onLine);
      });
  }, []);

  return hasAdblock;
};

export default useAdBlock;
