/* eslint-disable no-console */

'use client';

import { useReportWebVitals } from 'next/web-vitals';

import { sendLog } from '@/services/logs';

export default function WebVitals() {
  useReportWebVitals((metric) => {
    try {
      sendLog('app_web_vitals', {
        metric,
      });
    } catch (error) {
      console.error('Error sending web vitals log', error);
    }
  });
  return null;
}
