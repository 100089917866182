import { UploaderStatus } from '../../types/image';
import { UploaderImageStatusChangedCallback } from '../../types/job';

const getImageCallbacksByStatus = <T extends UploaderStatus>(
  callbacks: UploaderImageStatusChangedCallback<UploaderStatus>[],
  status: T,
) =>
  callbacks.filter((callback) =>
    callback.statuses.includes(status),
  ) as unknown as UploaderImageStatusChangedCallback<T>[];

export default getImageCallbacksByStatus;
