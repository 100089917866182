'use client';

import { useEffect } from 'react';

import { sendLog } from '@/services/logs';

export default function FeaturesTriggeredLogger({
  value: features,
}: {
  value: { id: string; variant: string }[];
}) {
  useEffect(() => {
    if (features)
      features.forEach((feature) => {
        sendLog('app_feature_abTriggered', {
          variantId: feature?.variant,
          featureId: feature?.id,
        });
      });
  }, [features]);

  return null;
}
