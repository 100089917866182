import { UploaderImage, UploaderStatus } from '../../types/image';

const imagesHasSameStatus = (
  images: UploaderImage<UploaderStatus>[],
  status: UploaderStatus,
): boolean =>
  images.every(
    (image) =>
      image.status === status ||
      image.previousStatuses.includes(status) ||
      image.status === UploaderStatus.Failed,
  );

export default imagesHasSameStatus;
