import { CropInfo } from '@web-apps/cropper';

import { Ratio } from '@/types/ratio';

import { UploaderStatus } from './image';
import {
  UploaderImageStatusChangedCallback,
  UploaderJobStatusChangedCallback,
} from './job';

export interface ContextUploader {
  allImagesCount: number;
  uploadedImagesCount: number;
  preparingJobsImagesCount: number;
  preparingJobsPreparedImagesCount: number;
}

export interface ContextUploaderAction {
  addImagesToUploader: AddImagesToUploader;
  removeImageFromUploader: (id: string) => void;
  reUploadImages: ReUploadImages;
}

export interface BucketUploadInfos {
  displayableUrl: string;
  uploadableUrl: string;
  storageKey: string;
}

export type AddImagesToUploader = (options: {
  images: File[];
  imageStatusChangedCallbacks?: UploaderImageStatusChangedCallback<UploaderStatus>[];
  jobStatusChangedCallbacks?: UploaderJobStatusChangedCallback<UploaderStatus>[];
  skipQualityResize?: boolean;
  dpi?: number;
  ratio?: Ratio;
}) => void;

export type ReUploadImages = (options: {
  images: {
    id: string;
    blobUrl: string;
    width: number;
    height: number;
    file: File;
    crop: CropInfo;
  }[];
  imageStatusChangedCallbacks?: UploaderImageStatusChangedCallback<UploaderStatus>[];
  jobStatusChangedCallbacks?: UploaderJobStatusChangedCallback<UploaderStatus>[];
}) => void;

export type ReduceImageWorkerPayload = {
  file: File;
  targetDpi?: number;
  ratio?: Ratio;
  quality?: number;
  id: string;
};

export enum WorkerResponseType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type ReduceImageWorkerResponse =
  | {
      type: WorkerResponseType.SUCCESS;
      width: number;
      height: number;
      crop: CropInfo;
      blobUrl: string;
      id: string;
      newFile?: File;
    }
  | {
      type: WorkerResponseType.ERROR;
      id: string;
      name: string;
      message: string;
    };

export type UploadImageWorkerPayload = {
  id: string;
  file?: File;
};

export type UploadImageWorkerResponse =
  | {
      type: WorkerResponseType.SUCCESS;
      id: string;
      url: string;
      storageKey: string;
    }
  | {
      type: WorkerResponseType.ERROR;
      id: string;
      message: string;
    };

export type XmpMetadata = Record<string, unknown>;
export type UploadXmpMetadataWorkerPayload = {
  file: File;
};
export type ReduceXmpMetadataWorkerResponse =
  | {
      type: WorkerResponseType.SUCCESS;
      maker: string;
      model: string;
      regions: unknown;
      hasRegions: boolean;
    }
  | {
      type: WorkerResponseType.ERROR;
      message: string;
    };
