/* eslint-disable new-cap */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { releaseProxy, Remote, wrap } from 'comlink';

import { WorkerConstructor } from './types';

class WorkerPool<T> {
  private stream = new TransformStream();

  private workers = new Map<Remote<T>, Worker>();

  private reader = this.stream.readable.getReader();

  constructor(
    public workerConstructor: WorkerConstructor,
    public numWorkers: number,
  ) {
    const writer = this.stream.writable.getWriter();
    for (let i = 0; i < numWorkers; i++) {
      const worker = new workerConstructor();

      const remote = wrap<T>(worker);

      this.workers.set(remote, worker);

      writer.write(remote);
    }

    writer.releaseLock();
  }

  private async waitNext(): Promise<Remote<T>> {
    const { value } = await this.reader.read();

    return value;
  }

  private release(worker: Remote<T>) {
    const writer = this.stream.writable.getWriter();
    writer.write(worker);
    writer.releaseLock();
  }

  async request(callback: (worker: Remote<T>) => any) {
    const worker = await this.waitNext();
    const returnValue = await callback(worker);

    this.release(worker);

    return returnValue;
  }

  async terminate() {
    const writer = this.stream.writable.getWriter();

    for (let i = 0; i < this.numWorkers; i++) {
      // eslint-disable-next-line no-await-in-loop
      const remote = await this.waitNext();
      const worker = this.workers.get(remote)!;
      remote[releaseProxy]();
      worker.terminate();
    }

    writer.releaseLock();
    this.reader.releaseLock();
    this.stream.writable.close();
    this.workers.clear();
  }
}

// eslint-disable-next-line import/prefer-default-export
export function workerPool<T>(
  ...args: ConstructorParameters<typeof WorkerPool>
) {
  return new WorkerPool<T>(...args);
}
