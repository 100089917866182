const getImageWidthHeight = async (
  url: string,
): Promise<{ width: number; height: number }> =>
  new Promise((resolve) => {
    const image = document.createElement('img');
    image.src = url;
    image.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = image;

      resolve({ width, height });
    };
  });

export default getImageWidthHeight;
