import { CropInfo } from '@web-apps/cropper';

import getDefaultImageCrop from '@/components/Cropper/_utils/getDefaultImageCrop';
import {
  CANVAS_MAX_PX_SIZE,
  IMAGE_FILE_TYPE,
  IMAGE_QUALITY,
} from '@/context/Uploader/constants';
import getImageOrientation from '@/context/Uploader/utils/images/getImageOrientation';
import { addImage } from '@/context/Uploader/utils/uploaderIndexedDB';
import { Ratio } from '@/types/ratio';

import fileToDataUrl from './fileToDataUrl';

const prepareImage = async ({
  id,
  file,
  dpi: targetDpi,
  ratio,
  quality = IMAGE_QUALITY,
}: {
  id: string;
  file: File;
  dpi?: number;
  ratio?: Ratio;
  quality?: number;
}): Promise<{
  id: string;
  newFile?: File;
  width: number;
  height: number;
  crop: CropInfo;
  blobUrl: string;
}> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const image = new Image();

    reader.onload = (event) => {
      image.src = (event?.target?.result as string) || '';
    };
    reader.readAsDataURL(file);

    image.onload = async () => {
      const canvas = document.createElement('canvas');
      const { naturalWidth: width, naturalHeight: height } = image;
      let newWidth = width;
      let newHeight = height;
      const orientation = getImageOrientation(width, height, undefined, ratio);

      const crop = getDefaultImageCrop({
        width,
        height,
        ratio,
        orientation,
      });

      if (ratio && targetDpi) {
        const actualDpi =
          (width * crop.width) /
          (orientation === 'portrait' ? ratio.min : ratio.max);

        // Reduce image size if targetDpi && dpi is higher than target dpi
        if (actualDpi > targetDpi) {
          // (dpi / newDpi) -> ratio
          newWidth = width * (targetDpi / actualDpi);
          newHeight = height * (targetDpi / actualDpi);

          // Reduce pixel size if too big for canvas limit by safari
        }
        const nbPixels = newWidth * newHeight;
        if (nbPixels > CANVAS_MAX_PX_SIZE) {
          // stay closest to the canvas limit
          const aspectRatio = newWidth / newHeight;
          newWidth = Math.sqrt(CANVAS_MAX_PX_SIZE * aspectRatio);
          newHeight = CANVAS_MAX_PX_SIZE / newWidth;
        }
        // Do not return the original file because the backend cannot interpret the exif orientation
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      const context = canvas?.getContext('2d');
      if (context) {
        context.fillStyle = 'white';
      }
      context?.fillRect(0, 0, canvas.width, canvas.height);
      context?.drawImage(image, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        async (blob) => {
          if (!blob) {
            reject(new Error('Blob is null'));
            return;
          }

          const blobUrl = window.URL.createObjectURL(blob as Blob);
          try {
            const dataUrl = await fileToDataUrl(blob);
            await addImage({ id, dataUrl });
            resolve({
              id,
              width: canvas.width,
              height: canvas.height,
              crop,
              blobUrl,
            });
          } catch (_) {
            const newFile = new File([blob as Blob], file.name, {
              type: IMAGE_FILE_TYPE,
            });
            resolve({
              id,
              newFile,
              width: canvas.width,
              height: canvas.height,
              crop,
              blobUrl,
            });
          }
        },
        IMAGE_FILE_TYPE,
        quality,
      );
    };
  });

export default prepareImage;
