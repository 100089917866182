'use client';

import { useEffect } from 'react';

import { Environments, INTERVAL_TIME_LOGS, LOCAL_LOGS_KEY } from '@/constants';
import getLocalStorageItem from '@/utils/localStorage/getLocalStorageItem';
import setLocalStorageItem from '@/utils/localStorage/setLocalStorageItem';
import getSessionStorageItem from '@/utils/sessionStorage/getSessionStorageItem';
import setSessionStorageItem from '@/utils/sessionStorage/setSessionStorageItem';

import sendClientSideLogs from '../client';
import { Log } from '../types';

const useLogs = (): void => {
  useEffect(() => {
    sendClientSideLogs(getLocalStorageItem(LOCAL_LOGS_KEY, []));
    setInterval(() => {
      const localLogs = getLocalStorageItem(LOCAL_LOGS_KEY, []);
      setLocalStorageItem(LOCAL_LOGS_KEY, []);

      sendClientSideLogs(localLogs).catch(() => {
        setLocalStorageItem(LOCAL_LOGS_KEY, localLogs);
      });

      if (
        process.env.NEXT_PUBLIC_GCP_ENV === Environments.DEV ||
        process.env.NEXT_PUBLIC_GCP_ENV === Environments.INTEGRATION
      ) {
        const sessionStorageLogs =
          getSessionStorageItem<Log[] | undefined>(LOCAL_LOGS_KEY, undefined) ||
          [];
        setSessionStorageItem(
          LOCAL_LOGS_KEY,
          sessionStorageLogs
            // Limiting to 500 logs to avoid memory space error
            .slice(-500)
            .concat(localLogs),
        );
      }
    }, INTERVAL_TIME_LOGS);
  }, []);
};

export default useLogs;
