export const NB_IMAGES_PREPARING_MAX =
  (typeof navigator !== 'undefined' && navigator.hardwareConcurrency) || 4;

export const NB_IMAGES_UPLOADING_MAX = 3;

export const UPLOADER_INDEXED_DB_NAME = 'image-uploader';
export const UPLOADER_INDEXED_DB_STORE_NAME = 'images';

export const PROMISE_RETRY_CONFIG = {
  retries: 6,
  minTimeout: 500,
  maxTimeout: 1000,
};

export const IMAGE_QUALITY = 0.9;
export const DEFAULT_CROP = {
  x: 0,
  y: 0,
  width: 1,
  height: 1,
};
export const DEFAULT_DPI = 300;
export const IMAGE_FILE_TYPE = 'image/jpeg';
export const CANVAS_MAX_PX_SIZE = 16777215;
