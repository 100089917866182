import cloneDeep from 'lodash/cloneDeep';

import { UploaderImage, UploaderStatus } from '../../types/image';
import { UploaderJob } from '../../types/job';
import getNextStatus from '../status/getNextStatus';
import imagesHasSameStatus from '../status/imagesHasSameStatus';
import isStatusAfter from '../status/isStatusAfter';

const updateJobsWithNextImage = <T extends UploaderStatus>(
  jobs: UploaderJob[],
  nextImage: UploaderImage<T>,
): UploaderJob[] => {
  const clonedJobs = cloneDeep(jobs);
  const jobWithNextImage = clonedJobs.find(({ images }) =>
    images.some(({ id }) => id === nextImage.id),
  );
  if (!jobWithNextImage) {
    return clonedJobs;
  }

  const jobIndexWithImage = jobs.findIndex(
    ({ id }) => id === jobWithNextImage?.id,
  );

  // Update image status in job
  jobWithNextImage.images = jobWithNextImage.images.map((imageInState) =>
    imageInState.id === nextImage.id ? nextImage : imageInState,
  ) as UploaderImage<UploaderStatus>[];

  // Update job status if all job images has the same status
  const shouldUpdatedJobStatus = imagesHasSameStatus(
    jobWithNextImage.images,
    nextImage.status,
  );

  if (shouldUpdatedJobStatus) {
    jobWithNextImage.status = nextImage.status;
  }

  if (jobWithNextImage.images.length) {
    // If the Job status is behind the image status because the image failed we update the job status
    while (
      jobWithNextImage.images.every((img) =>
        isStatusAfter(img.status, jobWithNextImage.status),
      )
    ) {
      jobWithNextImage.status = getNextStatus(jobWithNextImage.status);
    }
  } else {
    // if there is no more images in the job we set the job status to failed
    jobWithNextImage.status = UploaderStatus.Failed;
  }

  // Update jobs with jobWithNextImage
  const nextJobs = cloneDeep(jobs);
  nextJobs.splice(jobIndexWithImage, 1, { ...jobWithNextImage });

  return nextJobs;
};

export default updateJobsWithNextImage;
