'use client';

import { useContext, useEffect, useRef } from 'react';

import { sendLog } from '@/services/logs';
import removeCartItem from '@/stores/Cart/actions/image/removeCartItem';
import updateCartItemImageUrl from '@/stores/Cart/actions/image/updateCartItemImageUrl';
import useCartStore, {
  getCartState,
  removeCorruptedCartItems,
} from '@/stores/Cart/CartStore';
import { setOrder } from '@/stores/Order/OrderStore';
import { OrderStatus } from '@/stores/Order/types';
import isCartFilesUploaded from '@/utils/cart/isCartFilesUploaded';

import { UploaderContextAction } from '../Uploader';
import {
  UploaderJobReducerAction,
  UploaderReducerActionType,
} from '../Uploader/types/job';
import { getImage } from '../Uploader/utils/uploaderIndexedDB';

async function recoverPrintsBlobUrl() {
  const cartItems = getCartState().items;

  const imagesToReUpload: Extract<
    UploaderJobReducerAction,
    { type: UploaderReducerActionType.CreateReUploadJob }
  >['payload']['images'] = [];

  const newCartItemsPromises = cartItems.map(async (item) => {
    if (item.url || item.template) {
      return item;
    }

    const file = await getImage(item.fileId!).catch(() => undefined);
    const blobUrl = file ? URL.createObjectURL(file) : undefined;

    if (
      file &&
      blobUrl &&
      item.width &&
      item.height &&
      item.crop &&
      item.fileId
    ) {
      imagesToReUpload.push({
        blobUrl,
        crop: item.crop,
        file,
        height: item.height,
        id: item.fileId,
        width: item.width,
      });
    }

    return {
      ...item,
      blobUrl,
    };
  });

  const newCartItems = await Promise.all(newCartItemsPromises);

  useCartStore.setState((draft) => {
    draft.items = newCartItems;
  });

  return imagesToReUpload;
}

function CartSideEffects() {
  const { reUploadImages } = useContext(UploaderContextAction);

  const isReUploadTriggered = useRef(false);

  /**
   * Re-create blobUrl for images that were not uploaded before page refresh
   * Re-upload images that were not uploaded before page refresh
   * It will handle prints only (items without template)
   * Remove corrupted cart items
   */
  useEffect(() => {
    if (isReUploadTriggered.current) return;
    async function tryReUploadImages() {
      isReUploadTriggered.current = true;
      const images = await recoverPrintsBlobUrl();

      sendLog('cart_uploading_files_retry_after_refresh', {
        count: images.length,
      });

      if (images.length > 0) {
        reUploadImages({
          images,
          imageStatusChangedCallbacks: [
            updateCartItemImageUrl(),
            removeCartItem(),
          ],
        });
      }
      // Remove cart item if when image is not uploaded on page reload
      const { uploadingFilesRemovedCount, removedItemsCountsByProductId } =
        removeCorruptedCartItems();
      if (uploadingFilesRemovedCount > 0) {
        sendLog('cart_uploading_files_removed', {
          count: uploadingFilesRemovedCount,
          countByProductId: removedItemsCountsByProductId,
        });
      }
    }

    tryReUploadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set order status based on cart items
  const isAllFilesUploaded = useCartStore((store) =>
    isCartFilesUploaded(store.items),
  );
  const isCartEmpty = useCartStore((store) => store.items.length === 0);

  useEffect(() => {
    if (isCartEmpty) {
      setOrder((draft) => {
        draft.status = OrderStatus.Start;
      });
    } else {
      if (isAllFilesUploaded) {
        sendLog('isAllFilesUploaded', {
          value: isAllFilesUploaded,
        });
      }

      setOrder((draft) => {
        draft.status = isAllFilesUploaded
          ? OrderStatus.ReadyToSend
          : OrderStatus.UploadingFiles;
      });
    }
  }, [isAllFilesUploaded, isCartEmpty]);

  return null;
}

export default CartSideEffects;
