import { CartItem } from '@/stores/Cart/types';
import isAllAssetsUploaded from '@/utils/template/isAllAssetsUploaded';

const isCartFilesUploaded = (cart?: CartItem[]): boolean =>
  cart?.every(
    (item) =>
      !!item.url || (item.template && isAllAssetsUploaded(item?.template)),
  ) || false;

export default isCartFilesUploaded;
