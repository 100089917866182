import { UploaderImage, UploaderStatus } from '../../types/image';
import { UploaderJob } from '../../types/job';

const getImageById = <T extends UploaderStatus>(
  jobs: UploaderJob[],
  id: string,
  status?: T,
): UploaderImage<T> | undefined => {
  let image;

  jobs.forEach((job) => {
    const foundImage = job.images.find(
      (img) => img.id === id && (status ? img.status === status : true),
    );
    if (foundImage) image = foundImage;
  });

  return image;
};

export default getImageById;
