import { UploaderStatus } from '../../types/image';

const getNextStatus = (status: UploaderStatus): UploaderStatus => {
  const statusIndex = Object.values(UploaderStatus).indexOf(status);
  return Object.values(UploaderStatus)[
    statusIndex === Object.keys(UploaderStatus).length - 1
      ? statusIndex
      : statusIndex + 1
  ] as UploaderStatus;
};
export default getNextStatus;
