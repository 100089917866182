import { UploaderStatus } from '../../types/image';
import { UploaderJobStatusChangedCallback } from '../../types/job';

const getJobCallbacksByStatus = <T extends UploaderStatus>(
  callbacks: UploaderJobStatusChangedCallback<UploaderStatus>[],
  status: T,
) =>
  callbacks.filter((callback) =>
    callback.statuses.includes(status),
  ) as unknown as UploaderJobStatusChangedCallback<T>[];

export default getJobCallbacksByStatus;
