/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Remote } from 'comlink';

import { WorkerConstructor } from './types';
import { workerPool } from './worker-pool';

interface WorkerProxyTarget {
  terminate(): Promise<void>;
}

export function workerProxy<T>(
  workerConstructor: WorkerConstructor,
  maxWorkers: number,
) {
  const pool = workerPool(workerConstructor, maxWorkers);

  const target: WorkerProxyTarget = {
    terminate() {
      return pool.terminate();
    },
  };

  return new Proxy<Remote<T & WorkerProxyTarget>>(
    target as Remote<T & WorkerProxyTarget>,
    {
      get(_target, property, receiver) {
        if (property in target) return Reflect.get(_target, property, receiver);

        return (...args: any[]) =>
          pool.request(async (worker) => {
            const result = await Reflect.get(worker, property)(...args);

            return result;
          });
      },
    },
  );
}
