import { createContext } from 'react';

import { Brand } from '@/types/brand';

interface BrandContextType {
  brand: Brand;
}

// eslint-disable-next-line import/prefer-default-export
export const BrandContext = createContext<BrandContextType>(
  {} as BrandContextType,
);
