import { UploaderStatus } from '@/context/Uploader/types/image';

const isStatusAfter = (
  status: UploaderStatus,
  comparedStatus: UploaderStatus,
) =>
  Object.values(UploaderStatus).indexOf(status) >
  Object.values(UploaderStatus).indexOf(comparedStatus);

export default isStatusAfter;
